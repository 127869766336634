import styles from './index.module.scss';
import PlatformApiImage from '../../images/PlatformApi.png';

function PlatformApi() {
    return (
        <div className={styles.platformApi}>
            <p className={styles.subtitle}>PCX Payments API with the platform to back it up</p>
            <p className={styles.title}>Built for product people and developers</p>
            <img src={PlatformApiImage} alt=''/>
        </div>
    );
}

export default PlatformApi;
