import styles from './index.module.scss';

function Header() {

    const logIn = () => {
        window.open("https://platform.pcxpay.com","_blank")
    }

    return (
        <div className={styles.header}>
            <button onClick={logIn}>Log In</button>
        </div>
    );
}

export default Header;
