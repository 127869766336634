import styles from './app.module.scss';
import Footer from './components/footer';
import Header from './components/header';
import Hero from './components/hero';
import PlatformApi from './components/platformapi';
import Remittances from './components/remittances';
import Sales from './components/sales';

function App() {
  return (
    <div className={styles.App}>
      <Header/>
      <Hero/>
      <Remittances/>
      <PlatformApi/>
      <Sales/>
      <Footer/>
    </div>
  );
}

export default App;
