import styles from './index.module.scss';

function Hero() {
    return (
        <div className={styles.hero}>
            <div className={styles.textContainer}>
                <p className={styles.title}>
                    PCX
                </p>
                <p className={styles.subtitle}>
                    The Payments API For Everyone
                </p>
            </div>
        </div>
    );
}

export default Hero;
