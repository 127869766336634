import { useState } from 'react';
import styles from './index.module.scss';
import { OrbitProgress } from 'react-loading-indicators';

function Sales() {
    const [email, setEmail] = useState()
    const [isLoading, setIsLoading] = useState()
    const [formSuccess, setFormSuccess] = useState()
    const [formError, setFormError] = useState(false)

    const enquire = (event) => {
        event.preventDefault();
        setIsLoading(true)
        var params = {
            email: email
        }

        fetch(`https://b4hmjxx5ll.execute-api.eu-west-2.amazonaws.com/production/enquiry/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(params)
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response.data)
                if (response == 200) {
                    setFormSuccess(true)
                    setFormError(false)
                } else {
                    setFormSuccess(false)
                    setFormError(true)
                }
                setIsLoading(false)
                setEmail()
            })
            .catch((err) => {
                console.log(err.message);
                setIsLoading(false)
                setFormSuccess(false)
                setFormError(true)
            });
    }

    return (
        <div className={styles.sales}>
            <p className={styles.subtitle}>
                Speak to our sales team
            </p>
            <p className={styles.title}>
                Learn More
            </p>

            {
                isLoading ? (
                    <OrbitProgress color="#888" size="small" text="" textColor="" />
                ) : (
                    <form className={styles.inputContainer} onSubmit={enquire}>
                        <input placeholder='Your email address' onChange={(e) => setEmail(e.target.value)} required></input>
                        <button type="submit">Enquire</button>
                    </form>
                )
            }
            <p>
                {formSuccess && <span>Done! Sending you more info now</span>}
                {formError && <span>Something went wrong, try again</span>}
            </p>
        </div>
    );
}

export default Sales;
