import styles from './index.module.scss';
import FiatAndCrypto from '../../images/FiatAndCrypto.png';

function Remittances() {
    return (
        <div className={styles.remittances}>
            <div className={styles.left}>
                <p className={styles.subtitle}>Monitor payments, globally</p>
                <p className={styles.title}>The Best of both worlds</p>
                <p className={styles.text}>Get the tools to capture real time transaction data, whatever the method.</p>
            </div>
            <div className={styles.right}>
                <img src={FiatAndCrypto} alt=''/>
            </div>
        </div>
    );
}

export default Remittances;
